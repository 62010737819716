<template>
  <div class="frequency_box" v-loading="loading">
    <div class="title">
      {{ data.title }}
    </div>
    <div class="detail_time">
      {{ parseTime(data.createdAt) }} &nbsp; &nbsp;编辑：{{ data.authorName }} &nbsp; &nbsp; 点击量：{{ data.viewBaseNum }}
    </div>
    <div class="mian_img" v-html="data.content"></div>
    <p>
      <br>
      <br>
    </p>
    <share-con></share-con>
  </div>
</template>

<script>
  import {
    APPID
  } from '@/utils/api/config'
  import {
    parseTime
  } from "@/utils/tools"
  import shareCon from '@/components/shareCon'
  import fenxiang from '../../../public/shareConfig.js';
  export default {
    components: {
      shareCon
    },
    data() {
      return {
        detailId: undefined,
        loading: true,
        data: {}
      }
    },
    created() {
      if (this.$route.params.id) {
        this.detailId = this.$route.params.id
      }
      this.getInfo()
    },
    methods: {
      getInfo() {
        this.axios.get(`api/fusion/pc/getNewsInfo?appId=${APPID}&newsId=${this.detailId}`).then(res => {
          this.data = res.data
          fenxiang(this.data.title, '', 'http://o.cztvcloud.com/195/4727937/images/webologo.jpg', window.location.href);
          this.loading = false
        })
      }
    },
  };
</script>

<style lang="scss" scoped>
  .frequency_box {
    padding: 80px 30px 0;

    .title {
      width: 80%;
      margin: 0 auto;
      font-size: 36px;
      text-align: center;
      font-weight: bold;
    }

    .detail_time {
      text-align: center;
      margin: 0 auto;
      font-size: 14px;
      color: #666;
      margin-top: 20px;
      margin-bottom: 50px;
    }

    .mian_img {
      margin: 10px 0px;
      padding: 0 150px;
      // display: flex;
      // justify-content: center;

      // img {
      //   width: 650px;
      //   margin-bottom: 60px;
      // }
    }
  }

  // ::v-deep .main_img p img {
  //   display: block;
  //   margin: 25px auto;
  //   max-width: 90%;
  //   height: auto;
  // }
</style>
<style>
  p img {
    display: block;
    margin: 25px auto !important;
    max-width: 90%;
    height: auto;
  }
</style>
